body {
  line-height: 1;
  scrollbar-width: 0px;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  /* overflow-y: scroll;

  overflow-x: hidden; */
  font-family: "Roboto";
}
body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "coolvetica";
  src: url("../assets/fonts/coolvetica/coolvetica.woff") format("woff");
}
@font-face {
  font-family: "larissahandwriting";
  src: url("../assets/fonts/larissahandwriting/larissahandwriting.ttf")
    format("ttf");
}
@font-face {
  font-family: "australiacustom";
  src: url("../assets/fonts/australiacustom/australiacustom.woff")
    format("woff");
}

#root {
  z-index: 0;
}

a {
  pointer-events: none;
  cursor: default;
}
canvas {
  cursor: default;
  overflow: visible;
}

.myCanvasContainer {
  cursor: default;
  overflow: visible;
  pointer-events: none;
  background-color: #ffffff;
}

/* html {
  scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
  -moz-scroll-behavior: smooth;
  -ms-scroll-behavior: smooth;
  pointer-events: all;
} */
